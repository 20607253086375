<template>
    <div class="app_container" style="background: #59DEE6;overflow: hidden;">
        <div class="week_bg" style="background-image: url(img/weekLuckyMan/top_bg.png);">
            <div class="go_back_button" style="background-image: url(img/weekLuckyMan/go_back.png);" @click="goBack"></div>
        </div>
       <!-- <div class="st_share_button" style="background-image: url(img/share_button.png);" @click="goShare"></div> -->
 
                <!-- <div class="active_title_2" style="background-image: url(img/weekLuckyMan/active_rule_title.png);"></div> -->
                <div class="rule_box" style="background-image: url(img/weekLuckyMan/rule_text.png);">
                </div>
                <div class="bottom_bg">本活动最终解释权归光恋所有</div>
    </div>
</template>
  
<script>
import "@/assets/css/weekLuckyMan.css"

export default {
    name: 'activeRule',
    data() {
        return {
        }
    },
    created() {

    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }


    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  